
import Vue from "vue";
import { multiApiOperations, projects } from "@/api";
import { Actions } from "@/store/models";
import { getDate, sizeInMB, sizeInMBNumber } from "@/utils";
import ConfirmationDialog from "../common/ConfirmationDialog.vue";
export default Vue.extend({
  components: { ConfirmationDialog },
  name: "DashboardLatestProjects",
  data() {
    return {
      index: 1,
      selected: [],
      headers: [
        { text: "PROJECT NAME", value: "name" },
        { text: "LAST UPDATED", value: "dateEdited" },
        { text: "UPLOADED BY", value: "username" },
        { text: "PROJECT SIZE", value: "size" },
        { text: "GROUP NAME", value: "group" },
        { text: "STATUS", value: "status" },
        { text: "", value: "action" },
      ],
      items: [] as any,
      deleteProjectDialog: false,
    };
  },
  created() {
    this.getItems();
  },
  computed: {
    selectedIds: function (): number[] {
      return this.selected.map((p: { id: number }) => p.id);
    },
  },
  methods: {
    customSort(items: any[], sortBy: string[], sortDesc: boolean[]) {
      const index = sortBy[0];
      const isDesc = sortDesc[0];

      items.sort((a, b) => {
        if (index === "dateEdited") {
          const aTimestamp = Date.parse(a.updated_datetime);
          const bTimestamp = Date.parse(b.updated_datetime);
          if (!isDesc) {
            return aTimestamp - bTimestamp;
          } else {
            return bTimestamp - aTimestamp;
          }
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    async getItems() {
      try {
        this.items = [];
        const r = (await projects.getAllLatest((this as any).companyId)).data;
        r.forEach((p) => {
          this.items.push({
            name: p.name,
            dateEdited: getDate(p.updated_datetime || p.created_datetime),
            username: p.created_by,
            size: sizeInMBNumber(p.size),
            group: p.projects_associate_groups
              .map((g) => g.associate_group.name)
              .join(", "),
            status: p.status === 0 ? "Locked" : "Unlocked",
            id: p.id,
            updated_datetime: p.updated_datetime || p.created_datetime,
          });
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError);
      }
    },
    async download() {
      try {
        // await projects.download(this.selectedIds)
        await projects.generateDownloadZip(this.selectedIds);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError);
      }
    },
    async remove() {
      try {
        await multiApiOperations(projects.delete, this.selectedIds);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
      this.getItems();
      this.$emit("getActivityLog");
      this.$store.dispatch(Actions.GetCompanyProjects);
      this.$emit("updated");
    },
    view(id: number) {
      this.$router.push("projects/" + id);
    },
    gotoProjects() {
      this.$router.push("/projects");
    },
  },
});
